import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  CircularProgress,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import XLSX from "xlsx";
import useStyles from "./styles";
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useVendorState } from "../../context/VendorContext";
import { fetchFbaInventoryRecommendations } from "../../http/api";

const DEFAULT_WEEKS_TO_RECOMMEND_REPLENISHMENT = 8;

export default function FbaInventoryRecommendations(props) {
  const classes = useStyles();
  const vendorState = useVendorState();

  const [unModifiedTableData, setUnModifiedTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [
    weeksToRecommendReplenishment,
    setWeeksToRecommendReplenishment,
  ] = useState(DEFAULT_WEEKS_TO_RECOMMEND_REPLENISHMENT);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTable(true);
        const data = await fetchFbaInventoryRecommendations(
          vendorState.selected,
          DEFAULT_WEEKS_TO_RECOMMEND_REPLENISHMENT
        );
        setUnModifiedTableData(data?.data || []);
        setTableData(data?.data || []);
      } catch (error) {
        console.error("Error fetching FBA inventory recommendations", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    if (vendorState?.selected) {
      fetchData();
    }

    return () => {
      setUnModifiedTableData([]);
      setTableData([]);
    };
  }, [vendorState?.selected]);

  useEffect(() => {
      const newTableData = unModifiedTableData.map((item) => {
        return {
          ...item,
          recommendedReplenishmentQuantity:
            item.averageUnitsPerWeek * weeksToRecommendReplenishment,
          finalReplenishmentQuantity: Math.max(
            0,
            item.averageUnitsPerWeek * weeksToRecommendReplenishment -
              item.fbaInventory -
              item.inboundInventory -
              item.reservedInventory
          ),
        };
      });
      setTableData(newTableData);
  }, [unModifiedTableData, weeksToRecommendReplenishment]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title={`FBA Inventory Recommendations`}
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Operations
              </Link>
              <Typography className={classes.link} color="primary">
                {`FBA Inventory Recommendations`}
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "30%" }}
                  >
                    <InputLabel>
                      Select Weeks to Recommend Replenishment
                    </InputLabel>
                    <Select
                      value={weeksToRecommendReplenishment}
                      onChange={(e) => {
                        setWeeksToRecommendReplenishment(e.target.value);
                      }}
                      label="Select Weeks to Recommend Replenishment"
                      disabled={tableData.length === 0}
                    >
                      {[...Array.from({ length: 18 }, (_, i) => i + 1)]
                        .reverse()
                        .map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon fontSize="small" />}
                    onClick={() => {
                      const exportData = tableData.map((item) => {
                        return {
                          ASIN: item.asin,
                          Title: item.title,
                          "Orders (6 Weeks)": item.units6Weeks,
                          "Orders (12 Weeks)": item.units12Weeks,
                          "Orders (18 Weeks)": item.units18Weeks,
                          "Avg Orders (6 Weeks)": item.averageUnits6Weeks,
                          "Avg Orders (12 Weeks)": item.averageUnits12Weeks,
                          "Avg Orders (18 Weeks)": item.averageUnits18Weeks,
                          "Avg Orders (Per Week)": item.averageUnitsPerWeek,
                          [`Recommended Replenishment Quantity (${weeksToRecommendReplenishment} Weeks)`]: item.recommendedReplenishmentQuantity,
                          "FBA Inventory": item.fbaInventory,
                          "Inbound Inventory": item.inboundInventory,
                          "Reserved Inventory": item.reservedInventory,
                          "Final Replenishment Quantity":
                            item.finalReplenishmentQuantity,
                        };
                      });
                      const ws = XLSX.utils.json_to_sheet(exportData);
                      const wb = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                      XLSX.writeFile(
                        wb,
                        `FBA Inventory Recommendations - ${vendorState?.selected}.xlsx`
                      );
                    }}
                    disabled={isLoadingTable || tableData.length === 0}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              {isLoadingTable ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "620px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Table
                  tableData={tableData}
                  weeksToRecommendReplenishment={weeksToRecommendReplenishment}
                />
              )}
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
