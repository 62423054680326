import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  TextField,
  Box,
} from "@material-ui/core";
import api from "../../healpers/apiRoutes";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useVendorState } from "../../context/VendorContext";
import Table from "./components/Table";

//styles
import useStyles from "./styles";

import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { tokenConfig } from "../../context/UserContext";
import * as XLSX from "xlsx";

export default function CatalogActivity() {
  const exportFunctionRef = useRef();
  const classes = useStyles();

  //global
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const fetchCatalogActivities = async () => {
    setfullPageLoader(true);
    try {
      const params = {
        vendorCode: vendorState?.selected,
        search: searchValue,
      }
      const response = await axios(BASE_URL + api.fetchCatalogActivities, tokenConfig(params));
      console.log(response.data.data);
      setmainTableData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    if (vendorState?.selected) {
      fetchCatalogActivities();
    }
  }, [vendorState?.selected])

  const downloadVariants = async () => {
    try {
      const { data: { data: products } } = await axios(BASE_URL + api.catalogGetAPI, tokenConfig({ vendorCode: vendorState?.selected }));
      const productsWithVariants = [];
      products.forEach(product => {
        if (product.parentASIN) {
          const variant = {
            asin: product.asin,
            parentASIN: product.parentASIN,
            parentSKU: product.parentSKU,
            variantFamily: product.variantFamily,
            sku: product.sku,
            vcVendorCode: product.vcVendorCode || "",
            itemName: product.itemName,
          }
          productsWithVariants.push(variant);
        }
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(productsWithVariants, { header: ["asin", "parentASIN", "parentSKU", "variantFamily", "sku", "vcVendorCode", "itemName"] });

      const fileName = `${vendorState?.selected} Variants`;
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var worksheetFileDownloadEle = document.createElement("a");
      worksheetFileDownloadEle.download = fileName + ".xlsx";
      worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      worksheetFileDownloadEle.click();
    } catch (error) {
      console.log("Variants download error", error)
    }
  }

  const downloadBaselineSample = async () => {
    try {
      const products = [{
        asin: "B09L1R6XZ5",
        variantFamily: "10",
        sku: "TEST-SKU",
        parentSKU: "TEST-PARENT-SKU",
        itemName: "Test Name",
        productDescription: "Test product description",
        parentASIN: "B09L1R6XZ5",
        category: "Test category",
        subCategory: "Test sub-category",
      }];
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(products, { header: ["parentASIN", "parentSKU", "variantFamily", "asin", "sku", "itemName", "productDescription", "bulletPoint1", "bulletPoint2", "bulletPoint3", "bulletPoint4", "bulletPoint5", "category", "subCategory", "price", "vcVendorCode"] });

      const fileName = `Sample Baseline Sheet`;
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var worksheetFileDownloadEle = document.createElement("a");
      worksheetFileDownloadEle.download = fileName + ".xlsx";
      worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      worksheetFileDownloadEle.click();
    } catch (error) {
      console.log("Variants download error", error)
    }
  }

  function productDetailsFromAttributes({ asin, attributes, version }) {
    const product = {
      title: attributes.item_name[0].value,
      description: attributes.product_description ? attributes.product_description[0].value : "",
      brand: attributes.brand[0].value,
      asin: asin,
      bulletPoints: attributes.bullet_point ? attributes.bullet_point.map((bullet) => bullet.value) : [],
      version
    }
    console.log("product", product);
    return product;
  }

  const exportActivities = async (ids) => {
    try {
      const { data: { data: activities } } = await axios(BASE_URL + api.exportActivities, tokenConfig({ ids }));

      const catalogUpdates = [];
      activities.forEach(activity => {
        const update = productDetailsFromAttributes(activity);
        update.bulletPoints.forEach((bulletPoint, index) => {
          update[`bulletPoint${index + 1}`] = bulletPoint;
        })
        delete update.bulletPoints;
        catalogUpdates.push(update);
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(catalogUpdates, { header: ["asin", "brand", "title", "description", "version", "bulletPoint1", "bulletPoint2", "bulletPoint3", "bulletPoint4", "bulletPoint5"] });

      const fileName = `${vendorState?.selected} Activities`;
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelBlob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var worksheetFileDownloadEle = document.createElement("a");
      worksheetFileDownloadEle.download = fileName + ".xlsx";
      worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
      worksheetFileDownloadEle.click();
    } catch (error) {
      console.log("Variants download error", error)
    }
  }
  async function uploadVariants(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async evt => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const fileType = file.name.split(".")[1];

        if (fileType !== "xlsx") {
          throw new Error("Invalid file type. Please upload .xlsx file");
        }
        if (data[0][0] !== "asin") {
          throw new Error(`Column asin is missing`);
        }
        if (data[0][1] !== "parentASIN") {
          throw new Error(`Column parentASIN is missing`);
        }
        if (data[0][2] !== "parentSKU") {
          throw new Error(`Column parentSKU is missing`);
        }
        if (data[0][3] !== "variantFamily") {
          throw new Error(`Column variantFamily is missing`);
        }
        if (data[0][4] !== "sku") {
          throw new Error(`Column sku is missing`);
        }
        if (data[0][5] !== "vcVendorCode") {
          throw new Error(`Column vcVendorCode is missing`);
        }
        const headers = data[0];
        let formattedData = data.map(row => {
          let obj = {};
          for (let i = 0; i < headers.length; i++) {
            if (!row.length) {
              throw new Error("Don't leave any empty rows in the file");
            }
            if (!row[i]) {
              if (!(headers[i] === "vcVendorCode" && vendorState?.selected?.endsWith("_SC"))) {
                throw new Error(`Value missing for ${headers[i]}. Please check the file and try again`)
              } 
            }
            row[i] = row[i]?.toString() || "";
            obj[headers[i]] = headers[i] === "vcVendorCode" ? row[i].trim().replaceAll(" ", "") : row[i].trim();
          }
          return obj;
        });
        formattedData.shift();
        await axios.post(BASE_URL + api.changeVariants, {
          variants: formattedData,
          vendorCode: vendorState?.selected,
        },
          tokenConfig());
        alert("Variants updated successfully");
      } catch (error) {
        console.error(error);
        alert(error.message || "Error uploading variants");
      }
    };
    reader.readAsBinaryString(file);
  }

  async function uploadBaseline(e) {
    setfullPageLoader(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async evt => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const fileType = file.name.split(".")[1];

        //Validation
        if (fileType !== "xlsx") {
          throw new Error("Invalid file type. Please upload .xlsx file");
        };

        const validation = vendorState?.selected?.endsWith("_SC")
          ? ["asin", "sku",]
          : ["asin", "sku", "vcVendorCode"];
        const headers = ["parentASIN", "parentSKU", "variantFamily", "asin", "sku", "productType", "itemName", "productDescription", "bulletPoint1", "bulletPoint2", "bulletPoint3", "bulletPoint4", "bulletPoint5", "category", "subCategory", "price", "vcVendorCode"];
        const formattedData = data.map((row, index) => {
          validation.forEach((key) => {
            if (!Object.keys(row).includes(key)) {
              throw new Error(`Value missing for ${key} in row ${index + 2}. Please check the file and try again`);
            }
          });
          headers.forEach((key) => {
            const throwError = (message) => {
              throw new Error(
                `Invalid/Missing value for ${key} in row ${
                  index + 2
                }. ${message}. Please check the file and try again.`
              );
            };
            if (Object.keys(row).includes(key)) {
              const parsedValue = row[key].toString().trim();
              if (key === "parentASIN") {
                if (parsedValue.length >= 0) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should be empty or have a valid asin");
                }
              } else if (key === "variantFamily") {
                if (parsedValue.length === 0 || /^\d+$/.test(parsedValue)) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should be empty or have a numeric value");
                }
              } else if (key === "asin") {
                if (parsedValue.length !== 0) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should have a valid asin");
                }
              } else if (key === "sku") {
                if (parsedValue.length !== 0) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should not be empty");
                }
              } else if (["productType"].includes(key)) {
                if (parsedValue.length === 0 || parsedValue.length > 2) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should be empty or have more than 3 characters");
                }
              } else if (
                [
                  "bulletPoint2",
                  "bulletPoint3",
                  "bulletPoint4",
                  "bulletPoint5",
                  "category",
                  "subCategory",
                ].includes(key)
              ) {
                if (parsedValue.length === 0 || parsedValue.length > 2) {
                  row[key] = parsedValue;
                } else {
                  throwError("Should be empty or have more than 3 characters");
                }
              } else if (key === "price") {
                if (parsedValue.length === 0 || /^[0-9.]+$/.test(parsedValue)) {
                  row[key] = parseFloat(parsedValue);
                } else {
                  throwError("Should be empty or have a numeric value");
                }
              } else if (key === "vcVendorCode" && vendorState?.selected?.endsWith("_VC")) {
                if (parsedValue.length !== 0) {
                  row[key] = (parsedValue || "").replaceAll(" ", "");
                } else {
                  throwError("Should not be empty");
                }
              }
            }
          });
          row["parentageLevel"] = "Child";
          return row;
        });
        await axios.post(BASE_URL + api.uploadBaseline, {
          products: formattedData,
          vendorCode: vendorState?.selected,
        },
          tokenConfig());
        alert("Baseline data added successfully");
      } catch (error) {
        console.error(error);
        alert(error.message || "Error uploading baseline");
      } finally {
        setfullPageLoader(false);
      }
    };
    reader.readAsBinaryString(file);
  }

  async function uploadCategory(e) {
    setfullPageLoader(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async evt => {
      try {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const fileType = file.name.split(".")[1];

        //Validation
        if (fileType !== "xlsx") {
          throw new Error("Invalid file type. Please upload .xlsx file");
        }
        const validation = ["asin", "category", "subCategory"];
        const headers = ["category", "subCategory", "segment"];
        const formattedData = data.map((row, index) => {
          validation.forEach((key) => {
            if (!row[key]) {
              throw new Error(`Value missing for ${key} in row ${index + 2}. Please check the file and try again`);
            }
          });
          headers.forEach((key) => {
            if (row[key]) {
              row[key] = row[key].toString().trim();
            }
          });
          return row;
        });
        console.log('formattedData', formattedData);
        await axios.post(BASE_URL + api.uploadCategory, {
          products: formattedData,
          vendorCode: vendorState?.selected,
        },
          tokenConfig());
        alert("Baseline data added successfully");
      } catch (error) {
        console.error(error);
        alert(error.message || "Error uploading baseline");
      } finally {
        setfullPageLoader(false);
      }
    };
    reader.readAsBinaryString(file);
  }

const downloadCategorySample = async () => {
  try {
    const products = [{
      asin: "B09L1R6XZ5",
      segment: "Test Segment",
      category: "Test category",
      subCategory: "Test sub-category",
    }];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(products, { header: ["asin","segment", "category", "subCategory"] });
    const fileName = `Sample Category Sheet`;
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    var worksheetFileDownloadEle = document.createElement("a");
    worksheetFileDownloadEle.download = fileName + ".xlsx";
    worksheetFileDownloadEle.href = window.URL.createObjectURL(excelBlob);
    worksheetFileDownloadEle.click();
  } catch (error) {
    console.log("Category download error", error)
  }
}

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Catalog Activity"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                Catalog
              </Link>
              <Typography className={classes.link} color="primary">
                Catalog Activity
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadVariants}
                    style={{ width: 200, marginLeft: 20, fontSize: 10 }}
                  >
                    Download Variants
                  </Button>
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadVariants(e)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: 150, marginLeft: 20, fontSize: 10 }}
                    >
                      Upload Variants
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadBaselineSample}
                    style={{ width: 300, marginLeft: 20, fontSize: 10 }}
                  >
                    Download Baseline Sample
                  </Button>
                  <label htmlFor="upload-baseline">
                    <input
                      style={{ display: "none" }}
                      id="upload-baseline"
                      name="upload-baseline"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadBaseline(e)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: 150, marginLeft: 20, fontSize: 10 }}
                    >
                      Upload Baseline
                    </Button>
                  </label>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} style={{marginTop:'20px'}}>
                <label htmlFor="upload-category">
                    <input
                      style={{ display: "none" }}
                      id="upload-category"
                      name="upload-category"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadCategory(e)}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ width: 150, marginLeft: 20, fontSize: 10 }}
                    >
                      Upload Category
                    </Button>
                  </label>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadCategorySample}
                    style={{ width: 200, marginLeft: 20, fontSize: 10 }}
                  >
                    Download Category Sample
                  </Button>
                </Box>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    disabled={fullPageLoader}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <TextField
                        id="outlined-search"
                        label="Search"
                        variant="outlined"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        size="small"
                        style={{ width: 300, marginRight: 20 }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={fetchCatalogActivities}
                        style={{ width: 150, marginLeft: 20 }}
                      >
                        Search
                      </Button>
                    </Box>
                  </FormControl>
                </div>
              }
            >
              {fullPageLoader ? (
                <Typography>Loading...</Typography>
              ) : (
                <Table
                  ref={exportFunctionRef}
                  tableData={mainTableData}
                  exportActivities={exportActivities}
                />
              )}
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
