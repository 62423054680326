/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import "date-fns";
import Alert from '@material-ui/lab/Alert';
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig, useUserState } from "../../context/UserContext";
import { Calculate, Recycling } from "@mui/icons-material";
import ConfirmationPop from "../../components/Confirmation/Confirmation";
import Modal from "react-responsive-modal";
import * as XLSX from 'xlsx';
import PublishIcon from '@material-ui/icons/Publish';
import SampleFile from "../../healpers/assets/ForecastProjectionSample.xlsx";
import moment from "moment";

export default function SegmentProjections() {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();
  const userState = useUserState();

  const [forecastData, setForecastData] = useState([]);
  const [newProjections, setNewProjections] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [recalculateModal, setRecalculateModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [manualProjectionsModal, setManualProjectionsModal] = useState(false);
  const [selectedASINProjection, setSelectedASINProjection] = useState(0);
  const [asinRecalculateModal, setASINRecalculateModal] = useState(false);
  const [selectedASIN, setSelectedASIN] = useState("");
  const [excludeASINProjection, setExcludeASINProjection] = useState(false);
  const [err, setErr] = useState(null);
  const [notification, setNotification] = useState(null);
  const [selectedYear, setSelectedYear] = useState(2025);


  useEffect(() => {
    if (vendorState.selected && !fullPageLoader) {
      getSegmentProjectionData();
    }
  }, [vendorState.selected, selectedYear]);

  const getSegmentProjectionData = async (fromDB) => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
        fromDB,
        year: selectedYear
      };
      const result = await axios.get(
        BASE_URL + api.getSegmentForecast,
        tokenConfig(params)
      );
      const projections = parseProjections(result.data.data);
      const projectionsWithZeros = setDefaultProjection(projections);
      setForecastData(projectionsWithZeros);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("getSegmentProjectionData error", error);
    }
  };

  const parseProjections = (projections) => {
    const projectionObj = {};
    projections.forEach((projection) => {
      if(!projectionObj[projection.asin]) {
        projectionObj[projection.asin] = {
          asin: projection.asin,
        };
      }
      projectionObj[projection.asin][projection.forecastMonth] = projection.forecastProjection;
    });
    return Object.values(projectionObj);
  }

  const calculateSegmentProjections = async (reset) => {
    try {
      setRecalculateModal(false);
      setResetModal(false);
      setFullPageLoader(true);
      const body = {
        vendorCode: vendorState?.selected,
        reset,
        date: moment().startOf('year').format("YYYY-MM-DD")
      };
      const result = await axios.post(
        BASE_URL + api.calculateSegmentProjections,
        body,
        tokenConfig()
      );
      const projections = parseProjections(result.data.data);
      const projectionsWithZeros = setDefaultProjection(projections);
      setForecastData(projectionsWithZeros);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("calculateSegmentProjections error", error);
    }
  }

  const setDefaultProjection = (projections) => {
    const projectionsWithZeros = projections.map(projection => {
      const months = [];
      for (let i = 1; i <= 12; i++) {
        const month = i < 10 ? `0${i}` : `${i}`;
        months.push(`${month}/2025`);
      }

      months.forEach(month => {
        console.log(projection);
        if (!projection[month]) {
          projection[month] = 0;
        }
      });
      return projection;
    })
    return projectionsWithZeros;
  }

  const fetchASINSegmentForecast = async (asin) => {
    try {
      setFullPageLoader(true);
      const params = {
        asin: asin
      };
      const result = await axios.get(
        BASE_URL + api.getASINSegmentForecast,
        tokenConfig(params)
      );
      const segmentConfig = result.data.data;
      setSelectedASINProjection(segmentConfig?.forecastProjection);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("fetchASINSegmentForecast error", error);
    }
  }

  const changeASINProjection = async () => {
    try {
      setFullPageLoader(true);
      const body = {
        asin: selectedASIN,
        projection: parseInt(selectedASINProjection),
        excludeProjection: excludeASINProjection,
        vendorCode: vendorState?.selected,
      };
      await axios.post(
        BASE_URL + api.changeASINProjection,
        body,
        tokenConfig()
      );
      await getSegmentProjectionData(true);
      setASINRecalculateModal(false);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("changeASINProjection error", error);
    }
  }

  const validateManualProjection = (data) => {
    const forecastProjections = [];
    const forecastMonths = [];
    let isValid = true;
    data.forEach((value, idx) => {
      //check first row headers
      if (!value[0] === "ASIN") {
        setErr("ASIN is missing");
        isValid = false;
      }
      const monthRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
      if(idx === 0) {
        value.forEach((ele, index) => {
          if (index === 0) return;
          if(ele) {
            const validMonth = monthRegex.test(ele);
            if (!validMonth) {
              setErr("Forecast Month format is incorrect");
              isValid = false;
            } else {
              forecastMonths.push(ele);
            }
          }
        });
      }

      if (idx === 0) return;

      value.forEach((ele, index) => {
        if(index === 0) return;
        const projection = {
          asin: value[0],
          projection: ele,
          month: forecastMonths[index - 1]
        }
        forecastProjections.push(projection);
      });
    })
    return { isValid, forecastProjections };
  }

  async function uploadFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (evt) => {
      setErr(null)
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });

      const { isValid, forecastProjections } = validateManualProjection(data);

      if (!isValid) {
        document.getElementById('upload-csv').value = '';
        setManualProjectionsModal(false);
      } else {
        setNewProjections(forecastProjections);
        setManualProjectionsModal(true);
        document.getElementById('upload-csv').value = '';
      }

    };
    reader.readAsBinaryString(file);
  }

  const overrideProjections = async () => {
    try {
      setFullPageLoader(true);
      const body = {
        forecast: newProjections,
        vendorCode: vendorState?.selected,
      };
      await axios.post(
        BASE_URL + api.overrideProjections,
        body,
        tokenConfig()
      );
      await getSegmentProjectionData(true);
      setManualProjectionsModal(false);
      setFullPageLoader(false);
      setNotification("Projections updated successfully");
    } catch (error) {
      setFullPageLoader(false);
      setManualProjectionsModal(false);
      setErr("Error updating projections");
      console.log("overrideProjections error", error);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Segment Projections"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Segment Projections
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <Box style={{ display: "flex", alignItems: "center" }}>
              <a
                href={SampleFile}
                download="Sample File.xlsx"
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  size="medium"
                  style={{ width: 250 }}
                  startIcon={<GetAppIcon />}
                >
                  Download Sample File
                </Button>
              </a>
              <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: '20px' }}>
                <label htmlFor="upload-csv">
                  <input style={{ display: 'none' }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadFile(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="medium"
                    startIcon={<PublishIcon />}
                  >
                    Upload
                  </Button>
                </label>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      label="Year"
                    >
                      <MenuItem value={2024}>2024</MenuItem>
                      <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                  </FormControl>
            </Box>
          }
        />
        {!fullPageLoader && <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                    className={classes.mainChartHeaderButton}
                  >
                    Export
                  </Button>
                  {
                    userState?.userData?.email?.includes("salesduo.com") ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Calculate />}
                        onClick={() => setRecalculateModal(true)}
                        className={classes.mainChartHeaderButton}
                      >
                        Recalculate
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Recycling />}
                        onClick={() => setResetModal(true)}
                        className={classes.mainChartHeaderButton}
                      >
                        Reset
                      </Button>
                    </div>
                    :null
                  }
                </div>
                
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={forecastData}
                vendorCode={vendorState?.selected}
                year={selectedYear}
                openASINRecalculateModal={(asin)=>{
                  setSelectedASIN(asin);
                  setASINRecalculateModal(true);
                  fetchASINSegmentForecast(asin);
                }}
              />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmationPop
        open={recalculateModal}
        onCancel={() => setRecalculateModal(false)}
        title="Recalculate Projections"
        subtitle={`Are you sure to recalcuate projections?`}
        onConfirm={() => calculateSegmentProjections(false)}
        activity={fullPageLoader}
        />
        <ConfirmationPop
        open={resetModal}
        onCancel={() => setResetModal(false)}
        title="Reset Projections"
        subtitle={`Are you sure to reset projections?`}
        onConfirm={() => calculateSegmentProjections(true)}
        activity={fullPageLoader}
        />
        <ConfirmationPop
        open={manualProjectionsModal}
        onCancel={() => setManualProjectionsModal(false)}
        title="Manually Override Projections"
        subtitle={`Are you sure to manually override projections?`}
        onConfirm={() => overrideProjections()}
        activity={fullPageLoader}
        />
        <Modal open={asinRecalculateModal} onClose={() => setASINRecalculateModal(false)} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
          <Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
            <Typography style={{ fontSize: 12, paddingRight: 10, fontWeight: 600 }}>ASIN :</Typography>
            <Typography style={{ fontSize: 12 }}>{selectedASIN}</Typography>
          </Grid>
          <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="number"
                value={selectedASINProjection}
                id="outlined-adornment-amount"
                name="asinProjection"
                onChange={(number) => {
                  setSelectedASINProjection(number.target.value);
                }}
                label="Projection"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeASINProjection}
                  onChange={() => setExcludeASINProjection(!excludeASINProjection)}
                  color="primary"
                />
              }
              label="Exclude Projection"
            />
          </Grid>
          <Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary" size="small" onClick={() => changeASINProjection()}>
              Submit
            </Button>
          </Grid>
        </Modal>
        {
        notification ?
          <Alert severity="success" style={{ position: 'absolute', bottom: 30, left: '48%' }}>{notification}</Alert>
          :
          <></>
        }
        {
          err ?
            <Alert severity="error" style={{ position: 'absolute', bottom: 30, left: '48%' }}>{err}</Alert>
            :
            <></>
        }
      </Container>
    </>
  );
}
