import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import moment from "moment";

export default forwardRef(function Tables(
  { tableData, retryCategoryDomain, toCategoryDomainFiles },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const StatusRenderer = (props) => {
    const status = props.value;
    if (props.data.isRetryNeeded) {
      return "FAILED";
    }
    if (
      props.data.status !== 2 &&
      (props.data.status === 0 || !props.data.isProcessed)
    ) {
      return "PENDING";
    }
    switch (status) {
      case 0:
        return "PENDING";
      case 1:
        return "READY TO DOWNLOAD";
      case 2:
        return "FAILED";
      default:
        return "UNKNOWN";
    }
  };

  const handleFile = async (props) => {
    toCategoryDomainFiles(props.data.id);
  };

  const mergeFile = async (props) => {
    const downloadURL = `${BASE_URL}${api.categoryDomainScrappingDownloadList}?id=${props.data.id}`;
    if (props.data.status === 1) {
      window.open(downloadURL, "_blank", "noopener,noreferrer");
    }
  };

  const ButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={(e) => handleFile(props)}
      >
        Check Queue
      </Button>
    );
  };

  const MergeFiles = (props) => {
    return props.data.isRetryNeeded ? (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => retryCategoryDomain(props.data.id)}
      >
        Retry
      </Button>
    ) : (
      <Button
        disabled={props.data.status !== 1 || !props.data.isProcessed}
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={(e) => mergeFile(props)}
      >
        Merge Files
      </Button>
    );
  };

  const TimeTakenRenderer = (props) => {
    const timeTaken = props.value;
    if (!timeTaken) {
      return "UNKNOWN";
    }
    return timeTaken;
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        frameworkComponents={{
          mergeFiles: MergeFiles,
          buttonRenderer: ButtonRenderer,
          statusRenderer: StatusRenderer,
          timeTakenRenderer: TimeTakenRenderer,
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName="User" field="createdBy" width={180} />
        <AgGridColumn
          headerName="Date Created"
          field="createdAt"
          cellRenderer={({ value }) => moment(value).format("MM-DD-YYYY")}
          width={120}
        />
        <AgGridColumn
          headerName="Time Created"
          field="createdAt"
          cellRenderer={({ value }) => moment(value).format("hh:mm A")}
          width={120}
        />
        <AgGridColumn headerName="Category" field="category" width={200} />
        <AgGridColumn headerName="Country" field="country" width={150} />
        <AgGridColumn
          headerName="Time Taken"
          field="timeTaken"
          cellRenderer="timeTakenRenderer"
          width={120}
        />
        <AgGridColumn
          headerName="Status"
          cellRenderer="statusRenderer"
          field="status"
        />
        <AgGridColumn
          cellRenderer="buttonRenderer"
          cellEditorParams={{
            cellRenderer: "buttonRenderer",
          }}
          filter={false}
          sortable={false}
          width={150}
        />
        <AgGridColumn
          cellRenderer="mergeFiles"
          cellEditorParams={{
            cellRenderer: "mergeFiles",
          }}
          filter={false}
          sortable={false}
          width={150}
        />
      </AgGridReact>
    </div>
  );
});
