import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as XLSX from "xlsx";
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import SampleFile from "../../healpers/assets/Category_Scrapping_Sample.xlsx";
import useStyles from "./styles";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { tokenConfig, useUserState } from "../../context/UserContext";

export default function Page(props) {
  const classes = useStyles();
  const exportFunctionRef = useRef();
  const userState = useUserState();
  const [mainTableData, setMainTableData] = useState([]);
  const [file, setFile] = useState({ name: "No File uploaded" });
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [err, setErr] = useState(null);

  async function uploadFile(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
      const fileType = file.name.split(".")[1];
      if (fileType === "xlsx") {
        if (data[0][0] !== "Category Name") {
          setErr(`Category Name is missing`);
          return;
        }
        if (data[0][1] !== "Category URL") {
          setErr(`Category URL is missing`);
          return;
        }
        if (!(data.length > 1)) {
          setErr("File is empty");
          return;
        }
      }

      try {
        const body = [];
        data.forEach((element, index) => {
          if (index !== 0) {
            body.push({
              category: element[0],
              url: element[1],
              startPage: element[2],
              endPage: element[3],
            });
          }
        });
        const res = await axios.post(
          BASE_URL + api.categoryDomainScrappingUpload,
          { categories: body },
          tokenConfig()
        );
        setNotification(res.data.msg);
        fetchData();
      } catch (error) {
        setErr(err);
        document.getElementById("upload-csv").value = "";
        setTimeout(() => {
          setErr(null);
        }, 2000);
        setFile({ name: "No File uploaded" });
      }
    };
    reader.readAsBinaryString(file);
    e.target.value = null;
  }

  const fetchData = () => {
    setFullPageLoader(true);
    axios
      .get(
        BASE_URL + api.categoryDomainScrappingList + userState.userData.email
      )
      .then((res) => {
        setMainTableData(res.data);
        setFullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setFullPageLoader(false);
      });
  };

  const retryCategoryDomain = async (categoryId) => {
    try {
      const res = await axios.post(
        BASE_URL + api.categoryDomainScrappingUploadRetry,
        { id: categoryId },
        tokenConfig()
      );
      setNotification(res.data.msg);
      fetchData();
    } catch (e) {
      console.error(e);
      setNotification("Category retry failed");
    }
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Category Domain ASINs"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Category Domain ASINs
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <form>
              <Box display="flex" alignItems="baseline">
                <a
                  href={SampleFile}
                  download="Sample File.xlsx"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    size="medium"
                    style={{ width: 250 }}
                    startIcon={<GetAppIcon />}
                  >
                    Download Sample File
                  </Button>
                </a>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginRight: "-5%" }}
                >
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadFile(e)}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      size="medium"
                      startIcon={<PublishIcon />}
                    >
                      Upload here
                    </Button>
                  </label>
                  <Typography
                    className={classes.link}
                    color="primary"
                    style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                  >
                    {file.name}
                  </Typography>
                </FormControl>
              </Box>
            </form>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                toCategoryDomainFiles={(id) =>
                  window.open(
                    `/app/prospects/categoryDomainFiles?id=${id}`,
                    "_blank"
                  )
                }
                retryCategoryDomain={retryCategoryDomain}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
