import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as XLSX from "xlsx";
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import SampleFile from "../../healpers/assets/Sample.xlsx";
import useStyles from "./styles";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();
  const userState = useUserState();
  const [mainTableData, setMainTableData] = useState([]);
  const [file, setFile] = useState({ name: "No File uploaded" });
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [notification, setNotification] = useState(null);
  const [err, setErr] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  async function uploadCSV(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
      const fileType = file.name.split(".")[1];

      const errPromise = new Promise((resolve, reject) => {
        if (fileType === "xlsx") {
          if (data[0][0] !== "ASIN") {
            reject(`Column ASIN is missing`);
          }
          if (!(data.length > 1)) {
            reject("File is empty");
          }
          data.slice(1, data.length).forEach((entry, index) => {
            if (entry.length !== 1 || entry[0][0].length === 0) {
              reject(`Invalid data at row ${index + 2}`);
            }
          });
          resolve("No error found");
        } else {
          const columns = ["ASIN"];
          for (const column of columns) {
            if (!data[0].includes(column)) {
              reject(`Column ${column} is missing`);
            }
          }
          if (data.length <= 1) {
            reject("Uploaded sheet has no data");
          }
          resolve("No error found");
        }
      });

      errPromise
        .then(() => {
          setFile(file);
          setConfirmDialog(true);
          convertSeconds((data.length - 1) * 5 * 2); //rough time to fetch API is 5 sec.
        })
        .catch((err) => {
          setErr(err);
          document.getElementById("upload-csv").value = "";
          setTimeout(() => {
            setErr(null);
          }, 2000);
          setFile({ name: "No File uploaded" });
          setConfirmDialog(false);
          setEstimatedTime(null);
        });
    };
    reader.readAsBinaryString(file);
    e.target.value = null;
  }

  async function uploadCSVBrands(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });
      const fileType = file.name.split(".")[1];

      const errPromise = new Promise((resolve, reject) => {
        if (fileType === "xlsx") {
          if (!(data.length > 1)) {
            reject("File is empty");
          }
          data.slice(1, data.length).forEach((entry, index) => {
            if (entry[0][0].length === 0) {
              reject(`Invalid data at row ${index + 2}`);
            }
          });
          resolve("No error found");
        } else {
          const columns = ["ASIN"];
          for (const column of columns) {
            if (!data[0].includes(column)) {
              reject(`Column ${column} is missing`);
            }
          }
          if (data.length <= 1) {
            reject("Uploaded sheet has no data");
          }
          resolve("No error found");
        }
      });

      errPromise
        .then(() => {
          setFile(file);
          setConfirmDialog(true);
          convertSeconds((data.length - 1) * 5 * 2); //rough time to fetch API is 5 sec.
        })
        .catch((err) => {
          setErr(err);
          document.getElementById("upload-csv").value = "";
          setTimeout(() => {
            setErr(null);
          }, 2000);
          setFile({ name: "No File uploaded" });
          setConfirmDialog(false);
          setEstimatedTime(null);
        });
    };
    reader.readAsBinaryString(file);
    e.target.value = null;
  }

  const retryFile = async (id, country) => {
    try {
      const body = {
        id,
        country,
      };
      await axios.post(BASE_URL + api.brandDomainFinderRetry, body);
    } catch (error) {
      console.log(error);
    }
  };

  function convertSeconds(seconds) {
    var convert = function (x) {
      return x < 10 ? "0" + x : x;
    };
    setEstimatedTime(
      convert(parseInt(seconds / (60 * 60))) +
        ":" +
        convert(parseInt((seconds / 60) % 60)) +
        ":" +
        convert(seconds % 60)
    );
  }

  const fetchData = () => {
    if (!selectedCountry) {
      setMainTableData([]);
      return;
    }
    setFullPageLoader(true);
    axios
      .get(BASE_URL + api.brandDomainFinderList + userState.userData.email, {
        params: { country: selectedCountry },
      })
      .then((res) => {
        setMainTableData(res.data);
        setFullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setFullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData, selectedCountry]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Brand Domain Finder"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <form>
              <Box display="flex" alignItems="baseline">
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                  min
                >
                  <InputLabel id="country">Country</InputLabel>
                  <Select
                    labelId="country"
                    id="demo-simple-select-filled"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    label="Country"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"US"}>US</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadCSVBrands(e)}
                      disabled={!selectedCountry}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      size="small"
                      startIcon={<PublishIcon />}
                      disabled={!selectedCountry}
                    >
                      Upload Brands
                    </Button>
                  </label>
                  <Typography
                    className={classes.link}
                    color="primary"
                    style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                  >
                    {file.name}
                  </Typography>
                </FormControl>
                <a
                  href={SampleFile}
                  download="Sample File.xlsx"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    size="small"
                    style={{ width: 220 }}
                    startIcon={<GetAppIcon />}
                  >
                    Download Sample File
                  </Button>
                </a>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginRight: "-5%" }}
                >
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => uploadCSV(e)}
                      disabled={!selectedCountry}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      size="small"
                      startIcon={<PublishIcon />}
                      disabled={!selectedCountry}
                    >
                      Upload here
                    </Button>
                  </label>
                  <Typography
                    className={classes.link}
                    color="primary"
                    style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                  >
                    {file.name}
                  </Typography>
                </FormControl>
              </Box>
            </form>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                retryFile={retryFile}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmDialog}
        toggleDialog={() => setConfirmDialog(!confirmDialog)}
        file={file}
        setFile={setFile}
        estimatedTime={estimatedTime}
        setNotification={setNotification}
        setErr={setErr}
        fetchData={fetchData}
        country={selectedCountry}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
