/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";
import moment from "moment";
import { differenceInMonths, parse } from "date-fns";
import _ from "lodash";

export default function Projections() {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  const [forecastData, setForecastData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [tableKeys, setTableKeys] = useState({
    currentYear: moment().year(),
    previousYear: moment().subtract(1, "year").year(),
    lastPreviousYear: moment().subtract(2, "year").year(),
  });
  const [forecastColumns, setForecastColumns] = useState([]);
  const [columns, setColumns] = useState({});
  const [selectedYear, setSelectedYear] = useState(2025);

  useEffect(() => {
    if (vendorState.selected) {
      getBcgForecastData();
    }
  }, [vendorState.selected, selectedYear]);

  
  const getBcgForecastData = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
        date: selectedYear === 2024 ? moment().subtract(1, 'year').toDate() : moment().toDate()
      };
      const result = await axios.get(
        BASE_URL + api.getBcgForecast,
        tokenConfig(params)
      );
      console.log("result", JSON.stringify(result.data.data.data));
      const forecastData = Object.values(result.data.data.data);
      const yearsArray = result.data.data.years;
      const currentYear = yearsArray[2];
      const previousYear = yearsArray[1];
      const lastPreviousYear = yearsArray[0];
      const yearObject = {
        currentYear,
        previousYear,
        lastPreviousYear,
      };
      // forecastData.map((item, index) => {
      //   item = calculateFinalForecast(item);
      //   item = generateWeeklyForecast(item);
      //   return item;
      // });
      setForecastData(forecastData);
      // setColumns(columns);
      // setForecastColumns(forecastColumns);
      setTableKeys(yearObject);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("error", error);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Projections"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Projections
              </Typography>
            </Breadcrumbs>
          }
        />
        {!fullPageLoader && <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      label="Year"
                    >
                      <MenuItem value={2024}>2024</MenuItem>
                      <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={forecastData}
                tableKeys={tableKeys}
                columns={columns}
                vendorCode={vendorState?.selected}
                forecastColumns={forecastColumns}
              />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          tableKeys={tableKeys}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
