import {
  Equalizer as EqualizerIcon,
  Dashboard as DashboardIcon,
  AttachMoneyRounded as AttachMoneyRoundedIcon,
  Label as LabelIcon,
  ViewQuilt as ViewQuiltIcon,
  Create as CreateIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  Repeat as RepeatIcon,
  LocalShipping as ShipmentIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";

export const structure = [
  { id: 0, type: "title", label: "MAIN", pageCode: "none" },
  {
    id: 1,
    label: "Sales",
    link: "/app/sales",
    pageCode: "sales",
    icon: <AttachMoneyRoundedIcon />,
    children: [
      //     { label: "Sales Overview", link: "/app/sales/sellerCentral"},
      {
        label: "Shipped COGS - VC",
        link: "/app/sales/shippedCOGS",
        pageCode: "shippedCOGS",
        accountType: ["vendor"],
      },
      {
        label: "Goals & Performance",
        link: "/app/sales/sellerCentral",
        pageCode: "goalsAndPeformance",
        accountType: ["seller"],
      },
      {
        label: "Geographic Insights",
        link: "/app/sales/geo",
        pageCode: "geographicInsights",
        accountType: ["seller"],
      },
      {
        label: "Ratings and Reviews",
        link: "/app/bi/productAndReview",
        pageCode: "ratingsAndReviews",
      },
      {
        label: "All Ratings and Reviews",
        link: "/app/bi/productRatingAndReviewAll",
        pageCode: "allRatingsAndReview",
      },
      // {
      //   label: "Key Metrics VC",
      //   link: "/app/sales/keymetricsvc",
      //   pageCode: "keyMetricsVC",
      // },
      {
        label: "Key Metrics",
        link: "/app/bi/keyMetrics",
        pageCode: "keyMetrics",
      },
    ],
  },
  {
    id: 2,
    label: "Business Intelligence",
    link: "/app/bi/",
    pageCode: "businessIntelligence",
    icon: <EqualizerIcon />,
    children: [
      {
        label: "JS Segment",
        link: "/app/bi/jsSegment",
        pageCode: "jsSegment",
      },
      {
        label: "Competition Benchmarking",
        link: "/app/bi/benchmark",
        pageCode: "competitionBenchmarking",
      },
      {
        label: "Products and Brands",
        link: "/app/bi/jsProductsAndBrands",
        pageCode: "productsAndBrands",
      },
      {
        label: "Segments",
        link: "/app/bi/segments",
        pageCode: "segments",
      },
      {
        label: "Competition Detailed Sales",
        link: "/app/bi/competitionSalesASIN",
        pageCode: "competitionDetailedSales",
      },
      {
        label: "Share of Voice",
        link: "/app/bi/voice",
        pageCode: "shareOfVoice",
      },
      // {
      //   label: "BCG Matrix",
      //   pageCode: "bcg",
      //   children: [
      //     {
      //       label: "BCG Overview",
      //       link: "/app/bi/bcgOverview",
      //       pageCode: "bcgOverview",
      //     },
      //     {
      //       label: "BCG Matrix",
      //       link: "/app/bi/bcgMatrix",
      //       pageCode: "bcgMatrix",
      //     },
      //   ],
      // },
      {
        label: "Forecast",
        pageCode: "forecast",
        children: [
          {
            label: "Projections",
            link: "/app/bi/projections",
            pageCode: "projections",
          },
          {
            label: "Health Check",
            link: "/app/bi/healthCheck",
            pageCode: "healthCheck",
          },
          {
            label: "Segment Projections",
            link: "/app/bi/segmentProjections",
            pageCode: "segmentProjections",
          },
          {
            label: "Segment Forecast Health",
            link: "/app/bi/segmentForecastHealth",
            pageCode: "segmentForecastHealth",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    label: "Creative",
    link: "/app/creative/",
    icon: <CreateIcon />,
    pageCode: "creative",
    children: [
      // {
      //   label: "Detailed Pages",
      //   link: "/app/creative/dp",
      //   pageCode: "detailedPage",
      // },
      // { label: "A+ Pages", link: "/app/creative/ap", pageCode: "aPlusPages" },
      // { label: "SEO", link: "/app/creative/cpyseo", pageCode: "seo" },
      {
        label: "Glance Views",
        link: "/app/creative/glanceViews",
        pageCode: "glanceViews",
      },
      {
        label: "Conversions",
        link: "/app/creative/conversions",
        pageCode: "conversions",
      },
      {
        label: "Product Images",
        link: "/app/creative/productImages",
        pageCode: "productImages",
      }
    ],
  },
  // {
  //   id: 4,
  //   label: "Advertising",
  //   link: "/app/adv/",
  //   icon: <LabelIcon />,
  //   pageCode: "advertising",
  //   children: [
  //     {
  //       label: "Day Parting — Day",
  //       link: "/app/sales/orderMetrics/Daily",
  //       pageCode: "dayPartingDay",
  //     },
  //     {
  //       label: "Day Parting — Hour",
  //       link: "/app/sales/orderMetricsHour",
  //       pageCode: "dayPartingHour",
  //     },
  //     {
  //       label: "Day Parting — ASINs Day",
  //       link: "/app/sales/orderMetricsASINs/Daily",
  //       pageCode: "dayPartingAsinsDay",
  //     },
  //     {
  //       label: "Day Parting — ASINs Hour",
  //       link: "/app/sales/orderMetricsASINs/Hourly",
  //       pageCode: "dayPartingAsins",
  //     },
  //     { label: "Price War", link: "/app/bi/priceWar", pageCode: "priceWar" },
  //     {
  //       label: "Products Not Serving",
  //       link: "/app/adv/cp",
  //       pageCode: "productsNotServing",
  //     },
  //     {
  //       label: "Campaigns out of Budget",
  //       link: "/app/adv/cob",
  //       pageCode: "CampaignsOutOfBudget",
  //     },
  //   ],
  // },
  {
    id: 6,
    label: "Operations",
    link: "/app/operations/",
    icon: <ViewQuiltIcon />,
    pageCode: "operations",
    children: [
      {
        label: "Search Suppressed ASINs",
        link: "/app/bi/merchantListingsReport",
        pageCode: "suppressedAsins",
      },
      // {
      //   label: "Event Notifications",
      //   link: "/app/bi/notifications",
      //   pageCode: "eventNotifications",
      // },
      // {
      //   label: "Inventory Management",
      //   link: "/app/operations/invmgmt",
      //   pageCode: "sinventoryManagement",
      // },
      {
        label: "Fulfilled by Amazon (FBA) Inventory Forecast",
        link: "/app/bi/FBAInventoryRep",
        pageCode: "fbaInventoryForecast",
      },
      {
        label: "FBA Inventory Recommendations",
        link: "/app/operations/fbaInventoryRecommendations",
        pageCode: "fbaInventoryRecommendations",
        accountType: ["seller"],
      },
      { label: "3P Report", link: "/app/bi/threep", pageCode: "threePReport" },
      {
        label: "Map Violators",
        link: "/app/bi/mapViolaters",
        pageCode: "mapViolators",
      },
      {
        label: "Stranded Inventory",
        link: "/app/operations/stranded-inventory",
        pageCode: "strandedInventory",
      },
      {
        label: "Account Health",
        link: "/app/operations/account-health",
        pageCode: "accountHealth",
      },
      {
        label: "FBA Reimbursements",
        link: "/app/operations/fba-reimbursements",
        pageCode: "fbaReimbursements",
      },
    ],
  },
  {
    id: 7,
    label: "Invoices Reconciliation",
    link: "/app/operations/",
    icon: <ViewQuiltIcon />,
    pageCode: "invoiceReconciliations",
    accountType: ["vendor"],
    children: [
      // {
      //   label: "Seller Central Invoices",
      //   link: "/app/operations/scinvoicereconciliation",
      //   pageCode: "sellerCentralInvoices",
      // },
      {
        label: "Vendor Central Invoices",
        link: "/app/operations/invoicesReconciliation",
        pageCode: "vendorCentralInvoices",
        accountType: ["vendor"],
      },
    ],
  },
  {
    id: 8,
    label: "ShortageClaims And Chargebacks",
    link: "/app/sc_cb/",
    pageCode: "shortageclaimsAndChargebacks",
    accountType: ["vendor"],
    icon: <DashboardIcon />,
    children: [
      // { label: "Seller Central", pageCode: "sellerCentral" },
      {
        label: "Vendor Central",
        pageCode: "vendorCentral",
        children: [
          {
            label: "Shortage Claims",
            link: "/app/sc_cb/claims",
            pageCode: "vendorCentral",
            accountType: ["vendor"],
          },
          {
            label: "Chargebacks",
            link: "/app/sc_cb/chargeBacks",
            pageCode: "vendorCentral",
            accountType: ["vendor"],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    label: "Amazon Advertising",
    link: "/app/amazonAdvertising/",
    icon: <LabelIcon />,
    pageCode: "amazonAdvertising",
    children: [
      {
        label: "Settings and Optimisation",
        pageCode: "settingsAndOptimisation",
        children: [
          {
            label: "Create Campaigns",
            link: "/app/ams/createCampaign",
            pageCode: "createCampaign",
          },
          {
            label: "Campaigns Optimisation",
            link: "/app/ams/amsCampaigns",
            pageCode: "campaignsOptimisation",
          },
          {
            label: "Account Settings",
            link: "/app/ams/vendorSettings",
            pageCode: "campaignsSettings",
          },
          {
            label: "Day Parting Rules",
            link: "/app/ams/dayParting",
            pageCode: "dayPartingRules",
          },
        ],
      },
      {
        label: "Reports",
        pageCode: "reports",
        children: [
          {
            label: "Campaigns Performance",
            link: "/app/reports/campaignPerformance",
            pageCode: "campaignPerformance",
          },
          {
            label: "ASINs Performance",
            link: "/app/reports/asinPerformance",
            pageCode: "asinPerformance",
          },
          {
            label: "Keywords Performance",
            link: "/app/reports/keywordPerformance",
            pageCode: "keywordPerformance",
          },
          {
            label: "Search Terms Performance",
            link: "/app/reports/searchTermPerformance",
            pageCode: "searchTermPerformance",
          },
          {
            label: "Products Target Performance",
            link: "/app/reports/targetPerformance",
            pageCode: "targetPerformance",
          },
          {
            label: "Budget Changes",
            link: "/app/reports/budgetChanges",
            pageCode: "budgetChanges",
          },
          {
            label: "Placement Analysis",
            link: "/app/reports/placementAnalysis",
            pageCode: "placementAnalysis",
          },
          {
            label: "Reach Trend",
            link: "/app/reports/reachTrend",
            pageCode: "reachTrend",
          },
        ],
      },
      {
        label: "AMS PPC Audit Leads",
        link: "/app/ams/amsPPCAudit",
        pageCode: "amsPPCAudit",
      },
      {
        label: "AMS Forecast",
        link: "/app/ams/forecast",
        pageCode: "amsForecast",
      }
    ],
  },
  {
    id: 31,
    label: "Uncategorised Reports",
    link: "/app/bi/",
    icon: <EqualizerIcon />,
    pageCode: "uncategorizedReports",
    children: [
      {
        label: "Brand Segments By Day",
        link: "/app/bi/brandSegments",
        pageCode: "brandSegmentsByDay",
      },
      {
        label: "Keywords By ASIN",
        link: "/app/bi/keywordsByASIN",
        pageCode: "keywordsByAsin",
      },
      {
        label: "Sales and Rank",
        link: "/app/bi/SalesAndRank",
        pageCode: "salesAndRank",
      },
    ],
  },

  {
    id: 61,
    label: "Catalog",
    link: "/app/catalog/",
    icon: <AssignmentIcon />,
    pageCode: "catalog",
    children: [
      {
        label: "Product Details",
        link: "/app/catalog/productDetails",
        pageCode: "productDetails",
      },
      {
        label: "Product Hi-Res Images",
        link: "/app/catalog/productHiResImages",
        pageCode: "productHiResImages",
      },
      {
        label: "ASIN SKU Mapping",
        link: "/app/catalog/asinSKUMapping",
        pageCode: "asingSkuMapping",
      },
      { label: "Brand Segments By Day", link: "/app/bi/brandSegments" },
      { label: "Keywords By ASIN", link: "/app/bi/keywordsByASIN" },
      { label: "Sales and Rank", link: "/app/bi/SalesAndRank" },
      {
        label: "Catalog Items",
        link: "/app/catalog/catalogItems",
        pageCode: "catalogItems",
      },
      {
        label: "Catalog Activity",
        link: "/app/catalog/activity",
        pageCode: "catalogActivity",
      },
      {
        label: "Cohesity",
        link: "/app/catalog/cohesity",
        pageCode: "cohesityLogs",
      },
      {
        label: "Detail Page",
        link: "/app/catalog/detailPage",
        pageCode: "detailPage",
      },
      {
        label: "SEO Update Check",
        link: "/app/catalog/seoUpdateCheck",
        pageCode: "seoUpdateCheck",
      },
      {
        label: "Catalog Image Manager",
        link: "/app/catalog/catalogImageManager",
        pageCode: "catalogImageManager",
      },
      {
        label: "Catalog Variations",
        link: "/app/catalog/catalogVariations",
        pageCode: "catalogVariations",
        accountType: ["seller"],
      },
    ],
  },
  {
    id: 62,
    label: "Apollo",
    link: "/app/prospects/",
    icon: <AssignmentIcon />,
    pageCode: "prospects",
    children: [
      {
        label: "Category ASINs",
        link: "/app/prospects/categoryASINs",
        pageCode: "categoryScrapping",
      },
      {
        label: "Brand Finder",
        link: "/app/prospects/brandFinder",
        pageCode: "brandFinder",
      },
      {
        label: "Brand Finder Global",
        link: "/app/prospects/brandFinderGlobal",
        pageCode: "brandFinderGlobal",
      },
      {
        label: "Category Domain ASINs",
        link: "/app/prospects/categoryDomainASINs",
        pageCode: "categoryDomainScrapping",
      },
      {
        label: "Brand Domain Finder",
        link: "/app/prospects/brandDomainFinder",
        pageCode: "brandDomainFinder",
      },
      {
        label: "Contact Finder",
        link: "/app/prospects/contactFinder",
        pageCode: "contactFinder",
      },
      {
        label: "Search Contacts",
        link: "/app/prospects/searchContacts",
        pageCode: "searchContacts",
      },
      {
        label: "Search Organisations",
        link: "/app/prospects/searchOrganisations",
        pageCode: "searchOrganisations",
      },
      {
        label: "Enrich Contacts",
        link: "/app/prospects/enrichContacts",
        pageCode: "enrichContacts",
      },
      {
        label: "Blocked Domains",
        link: "/app/prospects/blockedDomains",
        pageCode: "blockDomains",
      },
      {
        label: "All Brands",
        link: "/app/prospects/listBrands",
        pageCode: "listBrands",
      },
      {
        label: "All Contacts",
        link: "/app/prospects/listContacts",
        pageCode: "listContacts",
      },
      {
        label: "Empty Domains",
        link: "/app/prospects/listEmptyDomains",
        pageCode: "listEmptyDomains",
      },
      {
        label: "Domains LLM Validation",
        link: "/app/prospects/domainsLLMValidation",
        pageCode: "domainsLLMValidation",
      },
    ],
  },
  {
    id: 15,
    label: "FBA Shipment",
    link: "/app/fbaShipment/",
    pageCode: "fbaShipment",
    icon: <ShipmentIcon />,
    children: [
      {
        label: "Manage Shipments",
        link: "/app/fbaShipment/manageShipments",
        pageCode: "manageShipments",
      },
      {
        label: "Create New Shipment",
        link: "/app/fbaShipment/createNewShipment",
        pageCode: "createNewShipment",
      },
      {
        label: "Send Transport Details",
        link: "/app/fbaShipment/sendTransportDetails",
        pageCode: "sendTransportDetails",
      },
      {
        label: "Estimate Shipping Cost",
        link: "/app/fbaShipment/estimateShippingCost",
        pageCode: "estimateShippingCost",
      },
      {
        label: "Shipment Address",
        link: "/app/fbaShipment/shipmentAddress",
        pageCode: "shipmentAddress",
      },
      {
        label: "Print Labels / BOL",
        link: "/app/fbaShipment/printLabelsOrBol",
        pageCode: "printLabelsOrBol",
      },
      {
        label: "Case Pack Details",
        link: "/app/fbaShipment/casePackDetails",
        pageCode: "casePackDetails",
      },
      {
        label: "Shipment Config",
        link: "/app/fbaShipment/shipmentContact",
        pageCode: "shipmentContact",
      },
      {
        label: "Send Tracking Numbers",
        link: "/app/fbaShipment/sendTrackingNumbers",
        pageCode: "sendTrackingNumbers",
      },
    ],
  },
  {
    id: 16,
    label: "Content Revisions",
    link: "/app/content/",
    icon: <DescriptionIcon />,
    pageCode: "content",
    children: [
      {
        label: "Approve Content",
        link: "/app/content/approve",
        pageCode: "approveContent",
        accountType: ["seller"],
      },
      {
        label: "Update Content",
        link: "/app/content/update",
        pageCode: "updateContent",
        accountType: ["seller", "vendor"],
      },
      {
        label: "Changelog",
        link: "/app/content/changelog",
        pageCode: "changelog",
        accountType: ["seller", "vendor"],
      },
    ],
  },
  {
    id: 11,
    label: "WBR",
    link: "/app/wbr/",
    pageCode: "wbr",
    icon: <EqualizerIcon />,
    children: [
      { label: "Sales Conversion Funnel", link: "/app/wbr/funnel", pageCode: "funnel" },
      { label: "Action Items", link: "/app/wbr/actionItems", pageCode: "actionItems" },
      { label: "Key Output Metrics", link: "/app/wbr/keyOutputMetrics", pageCode: "keyOutputMetrics" },
      { label: "Key Input Metrics", link: "/app/wbr/keyInputMetrics", pageCode: "keyInputMetrics" },
      { label: "AMS", link: "/app/wbr/ams", pageCode: "ams" },
      // { label: "Finance Metrics", link: "/app/wbr/financeMetrics", pageCode: "financeMetrics" },
      // { label: "Operations Metrics", link: "/app/wbr/operationMetrics", pageCode: "operationMetrics" },
      { label: "AM Rules", link: "/app/am/rules", pageCode: "amRules" },
      { label: "Weekly Insights", link: "/app/wbr/weeklyInsights", pageCode: "weeklyInsights" },
    ],
  },
  { id: 71, type: "title", label: "SECONDARY", pageCode: "none" },
  {
    id: 9,
    label: "Users",
    link: "/app/users/",
    pageCode: "users",
    icon: <PersonIcon />,
    children: [
      { label: "Add User", link: "/app/users/register", pageCode: "usersAdd" },
      { label: "All User", link: "/app/users/list", pageCode: "usersList" },
      {
        label: "User Activity",
        link: "/app/users/activity",
        pageCode: "userActivity",
      },
    ],
  },
  {
    id: 10,
    label: "Onboarding",
    link: "/app/onboarding/",
    pageCode: "onboarding",
    icon: <RepeatIcon />,
    children: [
      {
        label: "ASINs",
        link: "/app/onboarding/asins",
        pageCode: "onboardingASINs",
      },
      {
        label: "Crons Management",
        link: "/app/onboarding/cronsManagement",
        pageCode: "cronsManagement",
      },
      {
        label: "Historic Data",
        link: "/app/onboarding/uploadHistoricData",
        pageCode: "uploadHistoricData",
      },
      // {
      //   label: "JS Segment",
      //   link: "/app/onboarding/jsSegment",
      //   pageCode: "jsSegment",
      // },
      {
        label: "AMS Onboarding",
        link: "/app/onboarding/ams",
        pageCode: "amsOnboarding",
      },
      {
        label: "Database Archives",
        link: "/app/onboarding/dbArchives",
        pageCode: "dbArchives",
      },
      {
        label: "Customer Settings",
        link: "/app/onboarding/customerSettings",
        pageCode: "customerSettings",
      },
      {
        label: "Customer Accounts",
        link: "/app/ams/customerAccounts",
        pageCode: "customerAccounts",
      },
      {
        label: "Reports Health",
        link: "/app/onboarding/reportsHealth",
        pageCode: "reportsHealth",
      },
      {
        label: "Deactivate Account",
        link: "/app/onboarding/deactivateAccount",
        pageCode: "deactivateAccount",
      },
      {
        label: "Merge Account",
        link: "/app/onboarding/mergeAccount",
        pageCode: "mergeAccount",
      },
      {
        label: "User Groups",
        link: "/app/onboarding/userGroups",
        pageCode: "userGroups",
      },
      ...(process.env.REACT_APP_ENV === "brand_finder"
        ? [
            {
              label: "D API Statistics",
              link: "/app/onboarding/dapiStatistics",
              pageCode: "dapiStatistics",
            },
          ]
        : []),
    ],
  },
];
