import React, { forwardRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { getFaviconUrl } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props) {
  const renderImage = ({ value }) =>
    `<img style="height: 40px; width: 40px" src="${getFaviconUrl(value)}">`;

  const rowStyle = { background: "white" };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
      >
        <AgGridColumn
          headerName={""}
          field={"asin"}
          width={100}
          cellRenderer={renderImage}
        />
        <AgGridColumn headerName="ASIN" field="asin" width={150} />
        <AgGridColumn headerName="Title" field="title" width={400} />
        <AgGridColumn
          headerName="Orders (6 Weeks)"
          field="units6Weeks"
          width={200}
        />
        <AgGridColumn
          headerName="Orders (12 Weeks)"
          field="units12Weeks"
          width={200}
        />
        <AgGridColumn
          headerName="Orders (18 Weeks)"
          field="units18Weeks"
          width={200}
        />
        <AgGridColumn
          headerName="Avg Orders (6 Weeks)"
          field="averageUnits6Weeks"
          width={220}
        />
        <AgGridColumn
          headerName="Avg Orders (12 Weeks)"
          field="averageUnits12Weeks"
          width={220}
        />
        <AgGridColumn
          headerName="Avg Orders (18 Weeks)"
          field="averageUnits18Weeks"
          width={220}
        />
        <AgGridColumn
          headerName="Avg Orders (Per Week)"
          field="averageUnitsPerWeek"
          width={240}
        />
        <AgGridColumn
          headerName={`Recommended Replenishment Quantity (${
            props?.weeksToRecommendReplenishment || 8
          } Weeks)`}
          field="recommendedReplenishmentQuantity"
          width={280}
        />
        <AgGridColumn
          headerName="FBA Inventory"
          field="fbaInventory"
          width={200}
        />
        <AgGridColumn
          headerName="Inbound Inventory"
          field="inboundInventory"
          width={200}
        />
        <AgGridColumn
          headerName="Reserved Inventory"
          field="reservedInventory"
          width={200}
        />
        <AgGridColumn
          headerName="Final Replenishment Quantity"
          field="finalReplenishmentQuantity"
          width={280}
        />
      </AgGridReact>
    </div>
  );
});
